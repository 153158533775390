import React, { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";

import { connect } from "react-redux";
import {
  updateRestaurantCategoryMenuList,
  updateRestaurantMenuList,
} from "../redux/actions/userDataActions";

import * as Api from "../api";

import "./../assets/css/custom.css";

import UploadFile from "./UploadFile";
import AlertMsg from "./AlertMsg";
import AddModifiers from "./AddModifiers";
import Loader from "./Loader";
import AddOptions from "./AddOptions";
import SwitchInput from "../containers/Settings/SwitchInput";

import styles2 from "./AddSettingItem.module.css";

import { CURRENCY_LIST, CURRENCY_SYMBOL, MENU_TYPE } from "../constants";
import { THEME_MODE } from "../constants/Theme";

const symbol = CURRENCY_LIST;

const OrderTypes = [
  { name: "Both", value: "both" },
  { name: "Dine In", value: "dinein" },
  { name: "Take Away", value: "takeaway" },
];

const AddSettingItem = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [isLoading, setIsLoading] = useState(true);
  const [menuTitle, setMenuTitle] = useState("");
  const [printDisplayMenu, setPrintDisplayMenu] = useState("");
  const [menuCategory, setMenuCategory] = useState([]);
  const [price, setPrice] = useState("");
  const [priceUnit, setPriceUnit] = useState("");
  const [takeawayPriceValue, setTakeawayPriceValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [imageList, setImageList] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [hideMenuThumbNailImg, setHideMenuThumbNailImg] = useState(false);
  const [allergiesValue, setAllergiesValue] = useState("");
  const [nutritionValue, setNutritionValue] = useState("");
  const [dishNotes, setDishNotes] = useState("");
  const [caloriesValue, setCaloriesValue] = useState("");
  const [menuTypeValue, setMenuTypeValue] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [modifierList, setModifierList] = useState([]);
  const [modifierDataList, setModifierDataList] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [optionDataList, setOptionDataList] = useState([]);
  const [disableCurrencyDropdown, setDisableCurrencyDropdown] = useState(true);
  const [menuOrderType, setMenuOrderType] = useState("");
  const [menuEnabled, setMenuEnabled] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const theme = createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper":
              {
                backgroundColor: theme_mode.card,
                maxHeight: 300,
              },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            "& .MuiSvgIcon-root": {
              color: theme_mode.iconBg2,
            },
          },
        },
      },
    },
    palette: {
      gray: {
        main: theme_mode.text4,
      },
    },
  });

  const textInputCss = {
    borderBottom: `1.5px solid ${theme_mode.text4} !important`,
    "& .MuiInputBase-root": {
      "&:before, :after, :hover:not(.Mui-disabled):before": {
        borderBottom: 0,
      },
    },
  };

  useEffect(() => {
    const { currencyIcon, itemData } = props;
    const currencyIconExist =
      typeof currencyIcon !== "undefined" &&
      currencyIcon !== null &&
      currencyIcon !== "";

    if (itemData) {
      const {
        menuType = "",
        hideMenuThumbNailImages = false,
        menuItemOrderType = OrderTypes?.[0]?.["value"],
      } = itemData?.extras || {};
      const {
        name,
        printName,
        description,
        price: { value, currency },
        images,
        allergies,
        nutritions,
        calories,
        dishnote,
        options,
        takeawayPrice,
        enabled,
      } = itemData || {};
      setDisableCurrencyDropdown(
        currencyIconExist && currency === currencyIcon
      );
      setMenuTitle(name);
      setPrintDisplayMenu(printName ?? "");
      setDescriptionValue(description);
      setPrice(value);
      setPriceUnit(currency);
      setTakeawayPriceValue(takeawayPrice?.value);
      setImageList(images);
      setAllergiesValue(allergies);
      setNutritionValue(nutritions);
      setCaloriesValue(calories);
      setDishNotes(dishnote);
      setOptionList(options);
      setOptionDataList(options);
      setMenuTypeValue(menuType ?? "");
      setHideMenuThumbNailImg(hideMenuThumbNailImages ?? false);
      setMenuOrderType(menuItemOrderType ?? OrderTypes?.[0]?.["value"]);
      setMenuEnabled(enabled);
    } else {
      setDisableCurrencyDropdown(currencyIconExist);
      setPriceUnit(currencyIconExist ? currencyIcon : "");
      setMenuTitle("");
      setPrintDisplayMenu("");
      setDescriptionValue("");
      setMenuCategory([]);
      setPrice("");
      setTakeawayPriceValue("");
      setImageList([]);
      setAllergiesValue("");
      setNutritionValue("");
      setCaloriesValue("");
      setDishNotes("");
      setModifierList([]);
      setModifierDataList([]);
      setOptionList([]);
      setOptionDataList([]);
      setMenuTypeValue(MENU_TYPE[0]);
      setHideMenuThumbNailImg(false);
      setMenuOrderType(OrderTypes?.[0]?.["value"]);
      setMenuEnabled(true);
    }
  }, [props.itemData, props.currencyIcon]);

  useEffect(() => {
    if (props.itemData) {
      let modifiers = [];
      props.itemData.modifiers.map((x) => {
        let list = [];
        x.items.map((y) => {
          let index = menuList.findIndex((z) => z._id == y);
          if (index >= 0) {
            list.push(menuList[index]);
          }
        });

        modifiers.push({ ...x, items: list });
      });

      setModifierList(modifiers);
      setModifierDataList(modifiers);
    }
  }, [menuList, props.itemData]);

  useEffect(() => {
    if (props.itemData != null && categoryList.length) {
      setMenuCategory(
        categoryList.filter((x) => x._id == props.itemData._idCategory)
      );
    }
  }, [props.itemData, categoryList]);

  useEffect(() => {
    let upload_list = imageList.filter((x) => x.upload == false);
    if (upload_list.length > 0) {
      setImageLoading(true);
    } else {
      setImageLoading(false);
    }
  }, [imageList]);

  useEffect(() => {
    if (props.restaurantCategoryList.length) {
      setCategoryList(JSON.parse(JSON.stringify(props.restaurantCategoryList)));
      if (props.restaurantMenuList.length) {
        setMenuList(JSON.parse(JSON.stringify(props.restaurantMenuList)));
        setIsLoading(false);
      } else {
        getMenuList(JSON.parse(JSON.stringify(props.restaurantCategoryList)));
      }
    } else {
      getCategoryList();
    }
  }, []);

  const getCategoryList = () => {
    let filter = "all";
    Api.getCategoryList(props.restaurantId, filter).then((response) => {
      if (response.success) {
        const data = response.data.rows.sort((a, b) => a?.order - b?.order);
        setCategoryList(data);
        getMenuList(data);
      } else {
        setIsLoading(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const getMenuList = (cat_list) => {
    setIsLoading(true);
    let filter = "?pageSize=300000&pageNum=1&orderBy=order&orderByDir=asc";
    Api.getMenuList(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setMenuList(data);
        props.updateRestaurantCategoryMenuList({
          categoryList: cat_list,
          menuList: data,
        });
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (/[^0-9.]/g.test(price)) {
      setMsgAlert({ open: true, message: "Invalid Price", msgType: "error" });
      return;
    }

    if (menuCategory.length === 0) {
      setMsgAlert({
        open: true,
        message: "Category is required",
        msgType: "error",
      });
      return;
    }

    if (menuTypeValue === "") {
      setMsgAlert({
        open: true,
        message: "Menu Type is required",
        msgType: "error",
      });
      return;
    }

    if (/[^0-9.]/g.test(caloriesValue) && caloriesValue) {
      setMsgAlert({
        open: true,
        message: "Calories - Only Numbers are allowed",
        msgType: "error",
      });
      return;
    }

    let data = {
      menuTitle: menuTitle,
      printName: printDisplayMenu,
      menuCategory: menuCategory,
      price: parseFloat(price).toFixed(2),
      takeawayPrice: takeawayPriceValue
        ? parseFloat(takeawayPriceValue).toFixed(2)
        : 0,
      description: descriptionValue,
      priceUnit: priceUnit,
      menuImageList: imageList,
      menuType: menuTypeValue,
      allergies: allergiesValue,
      nutritions: nutritionValue,
      calories: caloriesValue,
      dishnote: dishNotes,
      hideMenuThumbNailImages: hideMenuThumbNailImg,
      menuItemOrderType: menuOrderType,
      enabled: menuEnabled,
    };

    let type = "add";
    if (props.itemData != null) {
      type = "edit";
      data = {
        ...props.itemData,
        ...data,
      };
    }

    onSubmitItem(data, type);
  };

  const onSubmitItem = (data, type) => {
    let imageData = [];
    data.menuImageList.forEach((item, index) => {
      imageData.push(item.name);
    });

    const modifiers = modifierDataList
      .filter((x) => x._id !== "add")
      .map((mod) => {
        return {
          header: mod?.header,
          required: mod?.required,
          addUpPrices: mod?.addUpPrices ?? false,
          multiSelection: mod?.multiSelection ?? false,
          extraAllowed: mod?.extraAllowed ?? false,
          defaultSelection: mod?.defaultSelection
            ? mod?.defaultSelection
            : null,
          items: mod.items.map((y) => y._id),
          ...(mod?.isNew ? {} : { _id: mod._id }),
        };
      });

    let options = [];
    optionDataList
      .filter((x) => x._id != "add")
      .map((x) => {
        let option_data = {
          header: x.header,
          required: x.required,
          items: x.items,
        };
        if (x?.isNew == true) {
        } else {
          option_data = { _id: x._id, ...option_data };
        }

        options.push(option_data);
      });

    let data1 = {
      name: data.menuTitle,
      printName: data?.printName ? data?.printName : null,
      _idCategory: data.menuCategory[0]._id,
      _idRestaurant: props.restaurantId,
      description: data.description,
      allergies: data.allergies ? data.allergies : null,
      nutritions: data.nutritions ? data.nutritions : null,
      calories: data.calories ? data.calories : null,
      dishnote: data.dishnote ? data.dishnote : null,
      extras: {
        menuType: data.menuType,
        hideMenuThumbNailImages: data.hideMenuThumbNailImages,
        menuItemOrderType: data.menuItemOrderType,
      },
      price: {
        value: data.price,
        currency: data.priceUnit,
      },
      takeawayPrice: {
        value: data.takeawayPrice,
        currency: data.priceUnit,
      },
      images: imageData,
      //modifiers: modifiers,
      //options: options,
      enabled: data.enabled,
    };

    let datas = JSON.stringify(data1);

    setBtnDisabled(true);
    if (type == "add") {
      Api.addMenu(datas, props.restaurantId).then((response) => {
        if (response.success) {
          let data = response.data;
          props.updateRestaurantMenuList([...props.restaurantMenuList, data]);
          setMsgAlert({
            open: true,
            message: "Added Successfully",
            msgType: "success",
          });
          props.onSubmit();
        } else {
          setMsgAlert({
            open: true,
            message: response.msg,
            msgType: "error",
          });
        }
        setBtnDisabled(false);
      });
    } else {
      Api.updateMenu(datas, props.restaurantId, data._id).then((response) => {
        if (response.success) {
          getMenuData(data._id);
        } else {
          setMsgAlert({
            open: true,
            message: response.msg,
            msgType: "error",
          });
          setBtnDisabled(false);
        }
      });
    }
  };

  const getMenuData = (menuId) => {
    Api.getMenuData(props.restaurantId, menuId).then((response) => {
      if (response.success) {
        let data = response.data;
        let list = [...props.restaurantMenuList];
        let findIndex = list.findIndex((x) => x._id == menuId);
        if (findIndex >= 0) {
          list[findIndex] = data;
          props.updateRestaurantMenuList(list);
        }
        setMsgAlert({
          open: true,
          message: "Updated Successfully",
          msgType: "success",
        });
        props.onSubmit();
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setBtnDisabled(false);
    });
  };

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const onRemoveImage = (data) => {
    setImageList([]);
    // let menuImage = [...imageList];
    // let imageIndex = menuImage.findIndex((item) => item.id == data.id);
    // if (imageIndex >= 0) {
    //   menuImage.splice(imageIndex, 1);
    //   setImageList(menuImage);
    // }
  };

  const uploadFile = (data, file) => {
    setImageList((prev) => [...prev, data]);
    setImageLoading(true);

    let formData = new FormData();
    formData.append("image", file);
    Api.uploadImageFile(formData).then((response) => {
      if (response.success) {
        data["name"] = response.data.fileId;
        data["image"] = response.data.location;
        data["upload"] = true;
        setImageList((prev) =>
          prev.map((el) => (el.id == data.id ? { ...el, ...data } : el))
        );
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const uploadError = (err) => {
    setMsgAlert({ open: true, message: err, msgType: "error" });
  };

  const handleOnChangeValue = (type, value) => {
    if (type === "takeaway") {
      setTakeawayPriceValue(value.replace(/[^0-9.]/g, ""));
    } else if (type === "menuItemOrderType") {
      setMenuOrderType(value);
    }
  };

  const handlerHideMenuThumbNailImg = () => {
    setHideMenuThumbNailImg(!hideMenuThumbNailImg);
  };

  const handlerMenuEnabled = () => {
    setMenuEnabled(!menuEnabled);
  };

  if (isLoading) {
    return <Loader height={{ height: window.innerHeight - 200 }} />;
  }

  return (
    <Grid
      component="form"
      onSubmit={onSubmit}
      container
      item
      direction="column"
      xs={12}
      sx={styles.mainGrid}
    >
      {/*<Grid container item xs={12} md={11}>*/}
      {/*  <Typography sx={styles.titleText}>*/}
      {/*    {props.itemData != null ? "Edit" : "Add"} a menu*/}
      {/*  </Typography>*/}
      {/*</Grid>*/}
      <Grid
        container
        item
        xs={12}
        md={11}
        justifyContent={"space-between"}
        pt={props.itemData?.modelId ? 1 : 0}
      >
        <Grid item xs={12} md={5}>
          <Grid container direction="row" item xs={12}>
            <UploadFile
              page={"AddSettingItem"}
              uploadText={"Drag and Drop your Images Here"}
              maxFiles={1}
              imageLength={imageList.length}
              imageLoading={imageLoading || btnDisabled}
              imageList={imageList}
              themeMode={props.themeMode}
              onRemoveImage={onRemoveImage}
              uploadFile={uploadFile}
              uploadError={uploadError}
            />
            {/*<Grid container*/}
            {/*      direction="column"*/}
            {/*      item xs={4}*/}
            {/*      alignItems="flex-end">*/}
            {/*  {imageList.map((item, index) => {*/}
            {/*    return (*/}
            {/*      <AddMenuImageItem*/}
            {/*        key={index}*/}
            {/*        data={item}*/}
            {/*        onRemoveImage={onRemoveImage}*/}
            {/*        page="AddSettingItem"*/}
            {/*      />*/}
            {/*    )*/}
            {/*  })}*/}
            {/*</Grid>*/}
          </Grid>

          <Grid sx={{ mt: 3 }}>
            <Grid item mb={3}>
              <Box className={`${styles2["hide-img-thumbnail"]}`}>
                <Typography style={styles.labelText} mr={1}>
                  Menu Enabled
                </Typography>
                <SwitchInput
                  themeMode={props.themeMode}
                  checked={menuEnabled}
                  onChange={handlerMenuEnabled}
                />
              </Box>
            </Grid>
            <Grid item mb={3}>
              <Box className={`${styles2["hide-img-thumbnail"]}`}>
                <Typography style={styles.labelText} mr={1}>
                  Hide Menu Thumb Nail Images
                </Typography>
                <SwitchInput
                  themeMode={props.themeMode}
                  checked={hideMenuThumbNailImg}
                  onChange={handlerHideMenuThumbNailImg}
                />
              </Box>
            </Grid>
            <Grid item sx={{ mb: 3 }}>
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  focused
                  fullWidth
                  color="gray"
                  id="title"
                  label="Menu Title"
                  value={menuTitle}
                  onChange={(e) => {
                    setMenuTitle(e.target.value);
                    setPrintDisplayMenu(e.target.value);
                  }}
                  variant="standard"
                  sx={textInputCss}
                  InputLabelProps={{
                    sx: { ...styles.inputLabelText },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { ...styles.inputText },
                  }}
                />
              </ThemeProvider>
            </Grid>
            <Grid item sx={{ mb: 3 }}>
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  focused
                  fullWidth
                  color="gray"
                  id="printDisplayMenu"
                  label="Print Display Menu"
                  value={printDisplayMenu}
                  onChange={(e) => setPrintDisplayMenu(e.target.value)}
                  variant="standard"
                  sx={textInputCss}
                  InputLabelProps={{
                    sx: { ...styles.inputLabelText },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { ...styles.inputText },
                  }}
                />
              </ThemeProvider>
            </Grid>
            <Grid item sx={{ mb: 3 }}>
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  multiline
                  focused
                  fullWidth
                  color="gray"
                  id="description"
                  rowsmin={2}
                  label="Description"
                  value={descriptionValue}
                  variant="standard"
                  sx={textInputCss}
                  InputLabelProps={{
                    sx: { ...styles.inputLabelText },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { ...styles.inputText },
                  }}
                  onChange={(e) => setDescriptionValue(e.target.value)}
                />
              </ThemeProvider>
            </Grid>
            <Grid item sx={{ mb: 3 }}>
              <label style={styles.labelText}>Category</label>
              <FormControl sx={{ m: 0, width: "100%" }}>
                <ThemeProvider theme={theme}>
                  <Select
                    required
                    variant="standard"
                    color="gray"
                    sx={{
                      color: theme_mode.inputVal,
                      fontSize: 13,
                      fontFamily: "InterBold",
                      ...textInputCss,
                    }}
                    value={menuCategory?.[0]?._id ? menuCategory?.[0]?._id : ""}
                    onChange={(event) =>
                      setMenuCategory(
                        categoryList.filter((x) => x._id == event.target.value)
                      )
                    }
                  >
                    {categoryList.map((item, index) => (
                      <MenuItem
                        sx={styles.menuItem}
                        key={index}
                        value={item._id}
                        divider={index < categoryList.length - 1}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {/*<Autocomplete*/}
                  {/*  multiple*/}
                  {/*  id="category"*/}
                  {/*  size="small"*/}
                  {/*  options={props.menuCategoryList}*/}
                  {/*  clearIcon={false}*/}
                  {/*  sx={textInputCss}*/}
                  {/*  value={menuCategory}*/}
                  {/*  renderTags={(value, getTagProps) =>*/}
                  {/*    value.map((option, index) => (*/}
                  {/*      <Chip*/}
                  {/*        sx={{*/}
                  {/*          border: '1px solid #000',*/}
                  {/*          borderRadius: 15,*/}
                  {/*          height: 20,*/}
                  {/*          fontSize: 11,*/}
                  {/*          fontFamily: 'InterBold',*/}
                  {/*          backgroundColor: '#fff'*/}
                  {/*        }}*/}
                  {/*        size="small"*/}
                  {/*        variant="outlined"*/}
                  {/*        key={option._id}*/}
                  {/*        label={option.name}*/}
                  {/*        deleteIcon={<RedCloseIcon height={13} width={13}/>}*/}
                  {/*        {...getTagProps({index})}*/}
                  {/*      />*/}
                  {/*    ))*/}
                  {/*  }*/}
                  {/*  onChange={(e, val) => setMenuCategory(val)}*/}
                  {/*  getOptionLabel={(option) => option.name}*/}
                  {/*  renderInput={(params) => (*/}
                  {/*    <TextField*/}
                  {/*      {...params}*/}
                  {/*      variant="standard"*/}
                  {/*      focused*/}
                  {/*      color="gray"*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*/>*/}
                </ThemeProvider>
              </FormControl>
            </Grid>
            <Grid item sx={{ mb: 3 }}>
              <label style={styles.labelText}>Price</label>
              <Grid container direction="row">
                <Grid item xs={2} style={{ width: "100%" }}>
                  <FormControl
                    style={{ width: "100%" }}
                    disabled={disableCurrencyDropdown}
                  >
                    <ThemeProvider theme={theme}>
                      <Select
                        required
                        variant="standard"
                        color="gray"
                        sx={{
                          ...textInputCss,
                          color: `${theme_mode.inputVal}`,
                          fontSize: 13,
                          fontFamily: "InterBold",
                          "&.MuiInputBase-root": {
                            "& .Mui-disabled": {
                              textFillColor: "#FFF !important",
                            },
                            "& .MuiSvgIcon-root": {
                              color: `${
                                disableCurrencyDropdown
                                  ? "transparent"
                                  : theme_mode.inputVal
                              }`,
                            },
                          },
                        }}
                        value={priceUnit}
                        onChange={(event) => setPriceUnit(event.target.value)}
                      >
                        {symbol.map((item, index) => (
                          <MenuItem
                            sx={styles.menuItem}
                            key={index}
                            value={item.currency}
                            divider={index < symbol.length - 1}
                          >
                            {item.symbol}
                          </MenuItem>
                        ))}
                      </Select>
                    </ThemeProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={10}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      required
                      focused
                      fullWidth
                      color="gray"
                      id="price"
                      variant="standard"
                      value={price}
                      onChange={(e) =>
                        setPrice(e.target.value.replace(/[^0-9.]/g, ""))
                      }
                      sx={textInputCss}
                      InputProps={{
                        autoComplete: "none",
                        sx: { fontSize: 13, fontFamily: "InterBold" },
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box
                              className="menu-price-box"
                              style={{
                                border: "0.5px solid " + theme_mode.text4,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        autoComplete: "none",
                        sx: { color: theme_mode.inputVal },
                      }}
                    />
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item mb={3}>
              <label style={styles.labelText}>Takeaway Price</label>
              <Grid container direction="row">
                <Grid item xs={2}>
                  <Typography
                    sx={{
                      color: `${theme_mode.inputVal}`,
                      borderBottom: `1.5px solid ${theme_mode.text4}`,
                    }}
                    className={`${styles2["take-away-price"]} ${styles2["currency"]}`}
                  >
                    {CURRENCY_SYMBOL?.[priceUnit]}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      focused
                      fullWidth
                      color="gray"
                      variant="standard"
                      value={takeawayPriceValue}
                      onChange={(e) => {
                        handleOnChangeValue("takeaway", e.target.value);
                      }}
                      InputProps={{
                        autoComplete: "none",
                        sx: { color: theme_mode.inputVal },
                        className: `${styles2["take-away-price"]} ${styles2["price"]}`,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box
                              className="menu-price-box"
                              sx={{
                                border: `0.5px solid ${theme_mode.text4}`,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ mb: 3 }}>
              <label style={styles.labelText}>Menu Type</label>
              <FormControl sx={{ m: 0, width: "100%" }}>
                <ThemeProvider theme={theme}>
                  <Select
                    required
                    variant="standard"
                    color="gray"
                    sx={{
                      color: theme_mode.inputVal,
                      fontSize: 13,
                      fontFamily: "InterBold",
                      ...textInputCss,
                    }}
                    value={menuTypeValue}
                    onChange={(event) => setMenuTypeValue(event.target.value)}
                  >
                    {MENU_TYPE.map((item, index) => (
                      <MenuItem
                        sx={styles.menuItem}
                        key={index}
                        value={item}
                        divider={index < MENU_TYPE.length - 1}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </ThemeProvider>
              </FormControl>
            </Grid>

            <Grid item mb={3}>
              <label style={styles.labelText}>Menu Item Order Type</label>
              <FormControl sx={styles.selectBoxFormControl}>
                <ThemeProvider theme={theme}>
                  <Select
                    required
                    variant="standard"
                    color="gray"
                    sx={{
                      color: theme_mode.inputVal,
                      ...styles.selectBox,
                      ...textInputCss,
                    }}
                    value={menuOrderType}
                    onChange={(event) =>
                      handleOnChangeValue(
                        "menuItemOrderType",
                        event.target.value
                      )
                    }
                  >
                    {OrderTypes.map((item, index) => (
                      <MenuItem
                        sx={styles.menuItem}
                        key={index}
                        value={item.value}
                        divider={index < OrderTypes.length - 1}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </ThemeProvider>
              </FormControl>
            </Grid>

            <Grid mt={3}>
              <ThemeProvider theme={theme}>
                <TextField
                  focused
                  fullWidth
                  multiline
                  rowsmin={2}
                  color="gray"
                  id="allergies"
                  label="Allergies"
                  value={allergiesValue}
                  variant="standard"
                  sx={textInputCss}
                  InputLabelProps={{
                    sx: { ...styles.inputLabelText },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { ...styles.inputText },
                  }}
                  onChange={(e) => setAllergiesValue(e.target.value)}
                />
              </ThemeProvider>
            </Grid>

            <Grid mt={3}>
              <ThemeProvider theme={theme}>
                <TextField
                  focused
                  fullWidth
                  multiline
                  rowsmin={2}
                  color="gray"
                  id="calories"
                  label="Calories"
                  value={caloriesValue}
                  variant="standard"
                  sx={textInputCss}
                  InputLabelProps={{
                    sx: { ...styles.inputLabelText },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { ...styles.inputText },
                  }}
                  onChange={(e) => setCaloriesValue(e.target.value)}
                />
              </ThemeProvider>
            </Grid>

            <Grid mt={3}>
              <ThemeProvider theme={theme}>
                <TextField
                  focused
                  fullWidth
                  multiline
                  rowsmin={2}
                  color="gray"
                  id="nutritions"
                  label="Nutrition's"
                  value={nutritionValue}
                  variant="standard"
                  sx={textInputCss}
                  InputLabelProps={{
                    sx: { ...styles.inputLabelText },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { ...styles.inputText },
                  }}
                  onChange={(e) => setNutritionValue(e.target.value)}
                />
              </ThemeProvider>
            </Grid>

            <Grid mt={3} mb={3}>
              <ThemeProvider theme={theme}>
                <TextField
                  focused
                  fullWidth
                  multiline
                  rowsmin={2}
                  color="gray"
                  id="dishNotes"
                  label="Dish Notes"
                  value={dishNotes}
                  variant="standard"
                  sx={textInputCss}
                  InputLabelProps={{
                    sx: { ...styles.inputLabelText },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { ...styles.inputText },
                  }}
                  onChange={(e) => setDishNotes(e.target.value)}
                />
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <AddModifiers
            page={"addMenu"}
            restaurantId={props.restaurantId}
            initialShowHeaderOnly={false}
            initialExpandModifier={false}
            categoryList={categoryList}
            menuList={menuList}
            modifiers={modifierList}
            menuItems={[]}
            modifierAddOption={true}
            editOrEditItem={"edit_item"}
            selectedModifier={null}
            currency={priceUnit}
            setModifierList={setModifierDataList}
            modifierEditable={true}
            showBackBtn={false}
            onBackBtnClick={() => null}
            onModifierClick={() => null}
            onSaveModifier={() => null}
            onDeleteModifier={() => null}
          />
          <Box mt={1}>
            <AddOptions
              options={optionList}
              setOptionList={setOptionDataList}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid mt={2} container direction="row" justifyContent="center">
        <Button
          type="button"
          sx={styles.cancelBtn}
          onClick={() => props.onCancel()}
        >
          Cancel
        </Button>
        <Button
          disabled={imageLoading || btnDisabled}
          type="submit"
          sx={styles.saveBtn}
        >
          {imageLoading || btnDisabled
            ? "Please Wait"
            : props.itemData == null
            ? "Add Menu"
            : "Update Menu"}
        </Button>
      </Grid>
      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantCategoryMenuList: (data) =>
      dispatch(updateRestaurantCategoryMenuList(data)),
    updateRestaurantMenuList: (data) =>
      dispatch(updateRestaurantMenuList(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    restaurantCategoryList: state.userData.restaurantCategoryList,
    restaurantMenuList: state.userData.restaurantMenuList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSettingItem);

const styles1 = (Theme) => ({
  mainGrid: {
    alignItems: "center",
    pb: 5,
    px: { md: 1, xs: 3 },
  },
  titleText: {
    color: Theme.titleText,
    fontFamily: "InterBold",
    textAlign: "left",
    fontSize: 18,
    pt: 1,
    pb: 3,
  },
  inputLabelText: {
    color: Theme.inputLabel,
    fontFamily: "InterRegular",
  },
  inputText: {
    color: Theme.inputVal,
    fontSize: 13,
    fontFamily: "InterSemiBold",
    my: 0.8,
  },
  labelText: {
    color: Theme.inputLabel,
    fontFamily: "InterRegular",
    fontSize: 12,
  },
  menuItem: {
    color: Theme.inputVal,
    fontSize: 13,
    fontFamily: "InterBold",
    "&.MuiMenuItem-divider": {
      borderBottom: "1px solid #DDD",
    },
    "&:hover": {
      backgroundColor: Theme.inputBg2,
      color: Theme.text,
    },
    "&.Mui-selected:hover": {
      backgroundColor: Theme.inputBg2,
      color: Theme.text,
    },
    "&.Mui-selected": {
      backgroundColor: Theme.inputBg5,
      color: Theme.text,
    },
  },
  cancelBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    color: Theme.text,
    fontFamily: "InterBold",
    fontSize: 13,
    borderRadius: 1,
    width: 120,
    height: 38,
    border: "2px solid" + Theme.btnBg5Border,
    backgroundColor: Theme.btnBg5,
    "&:hover": {
      backgroundColor: Theme.btnBg5 + " !important",
    },
  },
  saveBtn: {
    backgroundColor: Theme.btnBg4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    color: Theme.btnText,
    fontFamily: "InterBold",
    fontSize: 13,
    borderRadius: 1,
    marginLeft: 2,
    width: 120,
    height: 38,
    "&:hover": {
      backgroundColor: Theme.btnBg4 + " !important",
    },
  },
  selectBoxFormControl: {
    width: "100%",
    m: 0,
  },
  selectBox: {
    fontSize: 13,
    fontFamily: "InterBold",
  },
});
