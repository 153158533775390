import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputAdornment from "@mui/material/InputAdornment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import dayjs from "dayjs";

import { connect } from "react-redux";

import { THEME_MODE } from "../constants/Theme";
import { IS_LARGE_SCREEN } from "../constants";

import { ReactComponent as CalenderIcon } from "../assets/images/calender_gray.svg";

const ICON_SIZE = IS_LARGE_SCREEN ? 30 : 20;

const theme = (theme_mode) =>
  createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: theme_mode.card,
            border: "1px solid " + theme_mode.lightText,
            color: theme_mode.text,
            "& button": {
              fontFamily: "InterMedium !important",
            },
            "& .MuiTypography-root": {
              backgroundColor: theme_mode.card + " !important",
              color: theme_mode.text,
            },
            "& .MuiPickersFadeTransitionGroup-root": {
              color: theme_mode.text,
              fontFamily: "InterMedium !important",
            },
            "& .MuiButtonBase-root": {
              backgroundColor: theme_mode.card + " !important",
              color: theme_mode.text,
            },
            "& .MuiButtonBase-root:hover": {
              backgroundColor: theme_mode.card3 + " !important",
              color: theme_mode.text,
            },
            "& .Mui-selected": {
              backgroundColor: theme_mode.theme + " !important",
              color: theme_mode.text,
            },
            "& .Mui-selected:hover": {
              backgroundColor: theme_mode.card3 + " !important",
              color: theme_mode.text,
            },
          },
        },
      },
    },
  });

const SettingDatePicker = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  let bgColor = props.backgroundColor;

  const handlerOnClose = () => {
    setDatePickerOpen(false);
  };

  const handlerOnClick = () => {
    if (!props.disabled) {
      setDatePickerOpen(true);
    }
  };

  const handlerOnChange = (val) => {
    props.onChange(val);
  };

  return (
    <Box style={{ width: "100%" }}>
      {props?.label ? (
        <Box mb={0.8}>
          <Typography
            sx={[
              {
                fontSize: 12,
                fontFamily: "InterSemiBold",
                color: theme_mode.inputLabelText,
              },
              props?.labelStyle ? props.labelStyle : null,
            ]}
          >
            {props.label}
          </Typography>
        </Box>
      ) : null}
      <ThemeProvider theme={theme(theme_mode)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dayjs(props.value)}
            format={props.inputFormat}
            onChange={handlerOnChange}
            open={datePickerOpen}
            onClose={handlerOnClose}
            disabled={props.disabled}
            disableMaskedInput
            maxDate={props?.maxDate ?? null}
            minDate={props?.minDate ?? null}
            slotProps={{
              textField: {
                variant: "filled",
                onClick: handlerOnClick,
                sx: {
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderRadius: props?.borderRadius
                      ? props?.borderRadius
                      : "10px",
                    border: props?.border ? props.border : "0px",
                    height: props?.height
                      ? props.height
                      : IS_LARGE_SCREEN
                      ? 70
                      : 46,
                    fontFamily: "InterSemiBold",
                    fontSize: props?.inputFontSize
                      ? props.inputFontSize
                      : IS_LARGE_SCREEN
                      ? 17
                      : 12,
                    py: 1,
                    px:
                      typeof props.hideInput != "undefined"
                        ? `${props.hideInput == true ? 0 : 1} !important`
                        : 1,
                    backgroundColor: `${bgColor} !important`,
                    "&:before, :after, :hover:not(.Mui-disabled):before": {
                      borderBottom: 0,
                    },
                  },
                  "& .MuiFilledInput-input": {
                    color: props?.color
                      ? `${props.color} !important`
                      : theme_mode.inputVal,
                    p: "0 !important",
                    cursor: props?.cursor ? props.cursor : "default",
                    textAlign: props?.inputTextAlign
                      ? props.inputTextAlign
                      : "left",
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: props?.disabledColor
                      ? `${props?.disabledColor} !important`
                      : theme_mode.inputDisabledText,
                  },
                  "& .MuiFormLabel-root": {
                    display: "none !important",
                  },
                  "& .MuiInputAdornment-root": {
                    marginLeft: props?.marginLeft ? props?.marginLeft : "0px",
                    marginRight: props?.marginRight ? props?.marginRight : "8px",
                  },
                },
                InputLabelProps: {
                  shrink: true,
                  sx: {
                    mt: 0.8,
                    "& .MuiFormLabel-asterisk": {
                      display: "none",
                    },
                  },
                },
                inputProps: {
                  placeholder: props?.placeholder ?? "",
                },
                InputProps: {
                  startAdornment:
                    typeof props.leftView != "undefined" ? (
                      <InputAdornment position="start">
                        {props.leftView}
                      </InputAdornment>
                    ) : (
                      <InputAdornment>
                        <CalenderIcon width={ICON_SIZE} height={ICON_SIZE} />
                      </InputAdornment>
                    ),
                  endAdornment:
                    typeof props.rightView != "undefined" ? (
                      <InputAdornment position="end">
                        {props.rightView}
                      </InputAdornment>
                    ) : (
                      <InputAdornment>
                        <CalenderIcon width={ICON_SIZE} height={ICON_SIZE} />
                      </InputAdornment>
                    ),
                },
              },
            }}
          />
        </LocalizationProvider>
      </ThemeProvider>
      <Box>
        {props?.error ? (
          <Typography
            sx={{
              color: theme_mode.redText,
              fontSize: IS_LARGE_SCREEN ? 14 : 10,
              fontFamily: "InterMedium",
            }}
          >
            {props.error}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(SettingDatePicker);
