import React, { useState, useMemo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import * as Api from "../../api";

import { connect } from "react-redux";
import {
  updateRestaurantCategoryList,
  updateRestaurantMenuList,
} from "../../redux/actions/userDataActions";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import AddModifiers from "../../components/AddModifiers";
import ModifierMenuItems from "../../components/ModifierMenuItems";
import ConfirmAlert from "../../components/ConfirmAlert";

import styles from "./Modifiers.module.css";

const Modifiers = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [modifierList, setModifierList] = useState([]);
  const [selectedModifier, setSelectedModifier] = useState(null);
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);
  const [selectedMenuItemId, setSelectedMenuItemId] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [menuCategoryList, setMenuCategoryList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    setSelectedMenuItems(selectedModifier?.menuItemIds ?? []);
  }, [selectedModifier?.menuItemIds]);

  useEffect(() => {
    if (selectedMenuItemId) {
      onSetModifierMenu();
    }
  }, [selectedMenuItemId]);

  useEffect(() => {
    if (
      props.restaurantMenuList.length &&
      props.restaurantCategoryList.length
    ) {
      const list = props.restaurantCategoryList?.map((item) => {
        return {
          ...item,
          menuList: props.restaurantMenuList?.filter(
            (menu) => menu?._idCategory === item?._id
          ),
        };
      });
      setMenuCategoryList(list);
    }
  }, [props.restaurantMenuList, props.restaurantMenuList]);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = async () => {
    let filter = "all";
    const response = await Api.getCategoryList(props.restaurantId, filter);
    if (response.success) {
      const data = response.data.rows.sort((a, b) => a?.order - b?.order);
      props.updateRestaurantCategoryList(data);
      getMenuList();
    } else {
      setMsgAlert({ open: true, message: response.msg, msgType: "error" });
    }
  };

  const getMenuList = async () => {
    const filter = "?pageSize=300000&pageNum=1&orderBy=order&orderByDir=asc";
    const response = await Api.getMenuList(props.restaurantId, filter);
    if (response.success) {
      const menuList = response.data.rows;
      props.updateRestaurantMenuList(menuList);
      getMenuModifiers(menuList);
    } else {
      setMsgAlert({
        open: true,
        message: response.msg,
        msgType: "error",
      });
      setLoading(false);
    }
  };

  const getMenuModifiers = async (menuList) => {
    setLoading(true);
    const filter = `?pageSize=300000&pageNum=1`;
    const response = await Api.getMenuModifier(filter, props.restaurantId);
    if (response.success) {
      const filteredMenuList = menuList.filter(
        (x) => x?._idModifiers?.length > 0
      );
      const list = response?.data?.rows?.map((mod) => {
        const menuItemIds = filteredMenuList
          .filter((item) => item?._idModifiers?.includes(mod?._id))
          .map((x) => x._id);
        return {
          _idModifier: mod?._id,
          menuItemIds: menuItemIds,
          ...(mod?.modifiers?.[0] ?? null),
        };
      });
      setModifierList(list);
    } else {
      setMsgAlert({
        open: true,
        message: response.msg,
        msgType: "error",
      });
    }
    setLoading(false);
  };

  const onModifierClick = (item) => {
    if (item?._id !== selectedModifier?._id) {
      const { restaurantMenuList } = props;
      let list = [];

      item.items.forEach((itm) => {
        if (typeof itm === "string") {
          let index = restaurantMenuList.findIndex((z) => z._id === itm);
          if (index >= 0) {
            list.push(restaurantMenuList[index]);
          }
        } else {
          list.push(itm);
        }
      });

      const data = { ...item, items: list };
      setSelectedModifier(JSON.parse(JSON.stringify(data)));
    }
  };

  const onSelectModifierMenu = (menu) => {
    const menuId = menu?._id;
    setSelectedMenuItemId(menuId);
  };

  const onSetModifierMenu = () => {
    const menuId = selectedMenuItemId;
    setSelectedMenuItemId(menuId);
    let items = [...selectedMenuItems];
    if (items.includes(menuId)) {
      items = items.filter((x) => x !== menuId);
    } else {
      items.push(menuId);
    }
    setSelectedMenuItems(items);
    setSelectedMenuItemId(null);
  };

  const onSaveModifierMenu = () => {
    if (selectedModifier?._id !== "add") {
      const modifier = {
        ...selectedModifier,
        menuItemIds: selectedMenuItems,
      };

      const data = {
        id: [selectedModifier?._idModifier],
        menuItemIds: selectedMenuItems,
      };
      linkMenuModifiers(data, "edit", modifier);
    } else {
      let list = [...modifierList];
      const findIndex = list.findIndex((x) => x?._id === selectedModifier?._id);
      if (findIndex >= 0) {
        list[findIndex]["menuItemIds"] = selectedMenuItems ?? [];
        setModifierList(list);
      }
      const data = { ...selectedModifier, menuItemIds: selectedMenuItems };
      setSelectedModifier(data);
    }
  };

  const onSaveModifier = (type, data) => {
    const modData = {
      ...data,
      menuItemIds: selectedModifier?.menuItemIds ?? [],
    };
    createMenuModifiers(type, modData);
  };

  const createMenuModifiers = async (type, modifier) => {
    setLoading(true);
    let modData = {
      header: modifier.header,
      required: modifier.required,
      addUpPrices: modifier?.addUpPrices ?? false,
      multiSelection: modifier?.multiSelection ?? false,
      extraAllowed: modifier?.extraAllowed ?? false,
      defaultSelection: modifier?.defaultSelection
        ? modifier?.defaultSelection
        : null,
      items: modifier.items.map((y) => y._id),
    };
    if (modifier?.isNew !== true) {
      modData = { _id: modifier._id, ...modData };
    }

    const data = JSON.stringify({ modifiers: [modData] });

    const response =
      type === "edit"
        ? await Api.updateMenuModifier(
            props.restaurantId,
            data,
            modifier?._idModifier
          )
        : await Api.createMenuModifier(props.restaurantId, data);

    if (response.success) {
      if (type === "edit") {
        let list = [...modifierList];
        const findIndex = list.findIndex(
          (x) => x?._idModifier === modifier?._idModifier
        );
        if (findIndex >= 0) {
          list[findIndex] = modifier;
        }
        setModifierList(list);
        setSelectedModifier(null);
        setLoading(false);
      } else {
        const modId = response?.data?._id;
        const data1 = {
          id: [modId],
          menuItemIds: modifier?.menuItemIds,
        };
        modifier = {
          ...modifier,
          _idModifier: modId,
        };
        linkMenuModifiers(data1, "add", modifier);
      }
    } else {
      setLoading(false);
      setMsgAlert({
        open: true,
        message: response.msg,
        msgType: "error",
      });
    }
  };

  const linkMenuModifiers = async (data, type, modifier) => {
    setLoading(true);

    let unlinkedMenuItems = [];
    if (type === "edit") {
      unlinkedMenuItems = selectedModifier?.menuItemIds.filter(
        (x) => !selectedMenuItems.includes(x)
      );

      if (unlinkedMenuItems.length) {
        const unlinkData = {
          id: [selectedModifier?._idModifier],
          menuItemIds: unlinkedMenuItems,
        };

        const unlinkData1 = JSON.stringify(unlinkData);
        const unlinkResponse = await Api.unlinkMenuModifier(
          props.restaurantId,
          unlinkData1
        );
        if (!unlinkResponse.success) {
          setMsgAlert({
            open: true,
            message: unlinkResponse.msg,
            msgType: "error",
          });
          setLoading(false);
          return;
        }
      }
    }

    const data1 = JSON.stringify(data);
    const response = await Api.linkMenuModifier(props.restaurantId, data1);
    if (response.success) {
      let list = [...modifierList];
      if (type === "edit") {
        const findIndex = list.findIndex(
          (x) => x?._id === selectedModifier?._id
        );
        if (findIndex >= 0) {
          list[findIndex]["menuItemIds"] = selectedMenuItems ?? [];
          setModifierList(list);
        }
        setSelectedModifier(modifier);
      } else {
        list.push(modifier);
        setModifierList(list);
        setSelectedMenuItems([]);
        setSelectedModifier(null);
      }
      let menuList = [...props.restaurantMenuList];

      unlinkedMenuItems.forEach((menuItem) => {
        const findIndex = menuList.findIndex((menu) => menu._id === menuItem);
        if (findIndex >= 0) {
          menuList[findIndex]["_idModifiers"] = menuList[findIndex][
            "_idModifiers"
          ].filter((x) => x !== data.id?.[0]);
        }
      });

      data.menuItemIds.forEach((menuItem) => {
        const findIndex = menuList.findIndex((menu) => menu._id === menuItem);
        if (findIndex >= 0) {
          const idModifiers = menuList[findIndex]["_idModifiers"];
          if (!idModifiers.includes(data.id?.[0])) {
            menuList[findIndex]["_idModifiers"].push(data.id?.[0]);
          }
        }
      });
      props.updateRestaurantMenuList(menuList);
    } else {
      setMsgAlert({
        open: true,
        message: response.msg,
        msgType: "error",
      });
    }
    setLoading(false);
  };

  const onDeleteModifier = () => {
    setConfirmDelete(true);
  };

  const onCloseConfirmDelete = () => {
    setConfirmDelete(false);
  };

  const onConfirmDeleteModifier = async () => {
    setLoading(true);
    const response = await Api.deleteMenuModifier(
      props.restaurantId,
      selectedModifier?._idModifier
    );
    if (response.success) {
      setModifierList(
        modifierList.filter(
          (x) => x?._idModifier !== selectedModifier?._idModifier
        )
      );
      setSelectedModifier(null);
      setConfirmDelete(false);
      setSelectedMenuItems([]);
    } else {
      setMsgAlert({
        open: true,
        message: response.msg,
        msgType: "error",
      });
    }
    setLoading(false);
  };

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const onBackBtnClick = () => {
    navigate("/settings");
  };

  const MenuItemList = useMemo(() => {
    return (
      <ModifierMenuItems
        menuList={props.restaurantMenuList}
        menuCategoryList={menuCategoryList}
        selectedModifier={selectedModifier}
        selectedItems={selectedModifier?.menuItemIds ?? []}
        onSelectMenu={onSelectModifierMenu}
      />
    );
  }, [selectedModifier?._id, loading, menuCategoryList]);

  return (
    <Container page={"settings"} p={1.5}>
      {loading ? (
        <Loader />
      ) : (
        <Grid container item xs={12} justifyContent={"space-between"}>
          <Grid item xs={12} md={5.9}>
            <AddModifiers
              page={"modifier"}
              restaurantId={props.restaurantId}
              initialShowHeaderOnly={true}
              initialExpandModifier={true}
              categoryList={props.restaurantCategoryList}
              menuList={props.restaurantMenuList}
              modifiers={modifierList}
              menuItems={selectedModifier?.menuItemIds ?? []}
              modifierAddOption={true}
              editOrEditItem={"edit"}
              selectedModifier={selectedModifier}
              currency={props.restaurantDetail?.currecnyIcon ?? null}
              setModifierList={() => null}
              modifierEditable={true}
              showBackBtn={true}
              onBackBtnClick={onBackBtnClick}
              onModifierClick={onModifierClick}
              onSaveModifier={onSaveModifier}
              onDeleteModifier={onDeleteModifier}
              modifierListViewStyle={{
                maxHeight: window.innerHeight - 150,
                overflow: "auto",
              }}
            />
          </Grid>
          {props.restaurantMenuList.length && selectedModifier ? (
            <Grid item xs={12} md={5.9}>
              <Box className={`${styles["menu-main-view"]}`}>
                <Box className={`${styles["header-view"]}`}>
                  <Typography className={`${styles["header-text"]}`}>
                    Menu - {selectedModifier?.header ?? "Add"}
                  </Typography>
                </Box>
                {MenuItemList}
                <Box className={`${styles["footer-view"]}`}>
                  {JSON.stringify(selectedMenuItems) ===
                  JSON.stringify(selectedModifier?.menuItemIds ?? []) ? null : (
                    <Button
                      onClick={onSaveModifierMenu}
                      type="button"
                      className={`${styles["save-btn"]}`}
                    >
                      Save
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      )}
      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
      <ConfirmAlert
        confirmDelete={confirmDelete}
        title={"Are you sure?"}
        content={"You will not be able to recover this item"}
        firstBtn={"No"}
        secondBtn={loading ? "Please Wait" : "Yes ! Delete it"}
        btnLoading={loading}
        onCloseConfirmDelete={onCloseConfirmDelete}
        onConfirmDelete={onConfirmDeleteModifier}
        themeMode={props.themeMode}
      />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantMenuList: (data) =>
      dispatch(updateRestaurantMenuList(data)),
    updateRestaurantCategoryList: (data) =>
      dispatch(updateRestaurantCategoryList(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    restaurantId: state.userData.restaurantId,
    restaurantDetail: state.userData.restaurantDetail,
    restaurantMenuList: state.userData.restaurantMenuList,
    restaurantCategoryList: state.userData.restaurantCategoryList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modifiers);
